import { IPlanboardTrip } from "../../models/planboard-trip.model";
import { DISPLAYED_TRIP_STATUSES } from "../const/trip-statuses.const";
import moment from "moment";
import { IPlanboardDriverView } from "../../drivers/models/planboard-driver.model";
import { MINIMUN_NON_OCCUPIED_TIME } from "../const/const";
import { IPlanboardVehicleView } from "../../buses/models/vehicle.model";
import { IPlanboardActivity } from "../../models/activity.model";
import { IPlanboardDriverActivity } from "../../drivers/models/planboard-activity.model";
import { cloneDeep, sortBy } from "lodash";
import { DatetimeProvider } from "src/app/shared/helpers/date-time.functions";

interface DateTimeRange {
    startDateTime: Date;
    endDateTime: Date;
}

interface Range {
    rangeStart: Date;
    rangeEnd: Date;
}

type UniteActivity = Pick<IPlanboardDriverActivity, "startDateTime" | "endDateTime">;

enum PlanboardDataType {
    Driver = "DriverForPlanningGraphType",
    Vehicle = "BusForPlanningGraphType",
}

export function calcStatuses(trips: IPlanboardTrip[]): number {
    return trips.reduce((acc, trip) => {
        if (!acc.includes(trip.tripId)) {
            if (trip.status in DISPLAYED_TRIP_STATUSES) {
                acc.push(trip.tripId);
            }
        }
        return acc;
    }, []).length;
}

export const getDuration = (
    { startDateTime, endDateTime }: Pick<IPlanboardTrip | IPlanboardActivity, "startDateTime" | "endDateTime">,
    { rangeStart, rangeEnd }: Range
): number => {
    const startDate = moment.max(moment(startDateTime), moment(rangeStart));
    const endDate = moment.min(moment(endDateTime), moment(rangeEnd));

    return Math.max(endDate.diff(startDate), 0);
};

export const isNonOccupiedTimeAtLeastOneHour = (driverOrVehicle: IPlanboardDriverView | IPlanboardVehicleView, from: Date, to: Date): boolean => {
    const { trips, activities , __typename} = driverOrVehicle;
    const range = { rangeStart: from, rangeEnd: to };
    let nonOccupiedTimeRemaining = to.getTime() - from.getTime();
    const uniteActivities =
        __typename === PlanboardDataType.Driver
            ? uniteOverlappedActivities(activities as IPlanboardDriverActivity[])
            : activities;

    trips.forEach((trip) => {
        nonOccupiedTimeRemaining -= getDuration(trip, range);
    });

    uniteActivities.forEach((activity) => {
        nonOccupiedTimeRemaining -= getDuration(activity, range);
    });

    return nonOccupiedTimeRemaining >= MINIMUN_NON_OCCUPIED_TIME;
};

function uniteOverlappedActivities(activities: IPlanboardDriverActivity[]): UniteActivity[] {
    const uniteActivities: UniteActivity[] = [];
    activities = sortBy( cloneDeep(activities), "startDateTime", "asc");

    activities.forEach((activity, index) => {
        if (index === 0) {
            uniteActivities.push({
                startDateTime: activity.startDateTime,
                endDateTime: activity.endDateTime,
            });
            return;
        }
        const {startDateTime, endDateTime} = activity;
        const prevActivity = uniteActivities[uniteActivities.length - 1];
        const isStartOverlapped = DatetimeProvider.isBetween(
            startDateTime,
            prevActivity.startDateTime,
            prevActivity.endDateTime,
            '[)'
        );

        if (isStartOverlapped) {
            const isShouldExtendActivity = DatetimeProvider.isAfter(endDateTime, prevActivity.endDateTime);

            if (isShouldExtendActivity) {
                prevActivity.endDateTime = endDateTime;
            }

            return;
        }

        uniteActivities.push({
            startDateTime: activity.startDateTime,
            endDateTime: activity.endDateTime,
        });
    });
    return uniteActivities;
}
