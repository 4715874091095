/* src/app/planning/drivers/drivers-popup/drivers-popup.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.container {
  position: absolute;
  width: 450px;
  right: 24px;
  top: 65px;
  z-index: 10;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(94, 92, 142, 0.32);
  border-radius: 4px;
  padding: 8px 14px;
  font-weight: 600;
  font-size: 16px;
  color: #0b162d;
}
.indicator {
  display: inline-flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}
.light-green {
  background-color: #44bf90;
}
.green {
  background-color: #32946e;
}
.yellow {
  background-color: #ffc757;
}
.purple {
  background-color: #424bb0;
}
.underlined {
  border-bottom: 1px solid #f3f4f7;
}
.icon {
  margin-left: 4px;
  display: none;
}
.rotated {
  transform: rotate(180deg);
}
.row-block {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-block--hovered:hover {
  background-color: #f3f4f7;
  border-radius: 6px;
}
.row-block__data {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.row-block__data-quantity {
  width: 20px;
}
.row-block__data-quantity--calculated {
  visibility: hidden;
  display: flex;
  width: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #929ab3;
}
.row-block__data-name {
  display: inline-flex;
  width: 120px;
}
.row-block__data-name--unmarked {
  width: 132px;
  padding-left: 10px;
}
.row-block__buttons {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
}
.row-block__buttons .yellow:hover {
  background-color: #dfae4c;
}
.row-block__buttons .green:hover {
  background-color: #267355;
}
.row-block__buttons .purple:hover {
  background-color: #2f3789;
}
.row-block__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 88px;
  border-radius: 4px;
  margin-right: 8px;
  border: none;
  color: #ffffff;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
}
.row-block__button:not(:disabled):hover .icon {
  display: flex;
}
.row-block__button:disabled:hover {
  cursor: not-allowed;
}
/*# sourceMappingURL=drivers-popup.component.css.map */
