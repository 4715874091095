import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class MouseEventsService {
    public mouseCoordinates: { x: number; y: number } = { x: 0, y: 0 };

    constructor() {}

    public setMouseCoordinates(coordinates: { x: number; y: number }) {
        this.mouseCoordinates = coordinates;
    }
}
