import { Component, OnInit, ElementRef } from "@angular/core";
import { LegendService } from "./legend.service";
import { ILegend } from "./legend.interface";

@Component({
    selector: "ge-legend",
    templateUrl: "./legend.component.html",
    styleUrl: "./legend.component.scss",
})
export class LegendComponent implements OnInit {
    public legends: ILegend[] = [];
    public isCollapsed = true;

    constructor(
        private el: ElementRef,
        private legendService: LegendService,
    ) {}

    public ngOnInit(): void {
        this.legendService.fetchClientTourOperatorData().subscribe((res) => {
            this.legends = res.clients;
        });
    }

    public collapse(event: Event): void {
        if (this.el.nativeElement.contains(event.target)) {
            return;
        }
        this.isCollapsed = true;
    }

    public toggle(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}
