import { IBus } from "../../buses/models/vehicle.model";
import { IDriver } from "../../drivers/models/driver.model";
import { ITrip } from "../../trips/models/trip.model";
import { RENTAL_KEYS_TO_SEARCH, GENERAL_KEYS_TO_SEARCH } from "../const/const";

export function filterBusProperties(
    bus: IBus,
    properties: string[],
    query: string,
): boolean {
    return properties.some((x) => bus[x].toLowerCase().includes(query));
}

export function filterDriverProperties(
    driver: IDriver,
    properties: string[],
    query: string,
): boolean {
    return properties.some((x) => driver[x].toLowerCase().includes(query));
}

export function filterTripClient(trips: ITrip[], query: string): boolean {
    return trips.some((x) => x.client.toLowerCase().includes(query));
}

export function filterTripNumber(trips: ITrip[], query: string): boolean {
    return trips.some((x) => x.number.toString().toLowerCase().includes(query));
}

export function filterOrderNumber(trips: ITrip[], query: string): boolean {
    return trips.some((x) =>
        x.tripsHolder.order.id.toString().toLowerCase().includes(query),
    );
}

function filterTripsBySearchContext(item: any, query: string) {
    return item.trips.some(
        (trip) =>
            trip.searchContext &&
            !!trip.searchContext.match(new RegExp(query, "gi")),
    );
}

function getKeysToSearchBasedOnType(vehicleType: string): string[] {
    if (vehicleType === "RentalVehicle") {
        return RENTAL_KEYS_TO_SEARCH;
    }
    return GENERAL_KEYS_TO_SEARCH;
}

function containsQuery(
    value: any,
    query: string,
    keysToSearch: string[],
    keyName?: string,
): boolean {
    if (value == null) return false;

    if (typeof value === "object") {
        if (Array.isArray(value)) {
            return value.some((item) =>
                containsQuery(item, query, keysToSearch),
            );
        }

        // search by trips info
        if (value && value.trips && value.trips.length) {
            // search by client name
            if (
                value.trips.some(
                    (tripItem) =>
                        tripItem.orderClientName &&
                        tripItem.orderClientName
                            .toLowerCase()
                            .includes(query.toLowerCase()),
                )
            ) {
                return true;
            }
        }

        return Object.keys(value).some((key) => {
            if (keysToSearch.includes(key)) {
                return containsQuery(value[key], query, keysToSearch, key);
            }
            return (
                typeof value[key] === "object" &&
                containsQuery(value[key], query, keysToSearch, key)
            );
        });
    }

    switch (typeof value) {
        case "string": {
            if (keyName === "searchContext") {
                if (isNaN(Number(query))) {
                    return value
                        .toString()
                        .toLowerCase()
                        .includes(query.toLowerCase());
                } else {
                    return false;
                }
            } else {
                return value
                    .toString()
                    .toLowerCase()
                    .includes(query.toLowerCase());
            }
        }
        case "number": {
            return Number(value) === Number(query);
        }
        default: {
            return false;
        }
    }
}

export function filterBySearchContext(item: any, query: string): boolean {
    const keysToSearch = getKeysToSearchBasedOnType(item.vehicleType || "");
    return containsQuery(item, query, keysToSearch);
}

export function filter(item: any, query: string): boolean {
    return filterBySearchContext(item, query);
}

const reduce = (query, fieldName) => (res, data) => {
    const filteredData = data[fieldName].filter((item) => filter(item, query));

    return filteredData.length
        ? [...res, { ...data, [fieldName]: filteredData }]
        : res;
};

export function filterData(planboardData: any, query, fieldName) {
    return planboardData.reduce(reduce(query, fieldName), []);
}
