import gql from "graphql-tag";

export const LEGEND_FRAGMENT = gql`
    fragment legendFragment on Client {
        ... on TourOperatorClient {
            color
            name
        }
    }
`;
