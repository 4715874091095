import { IFilter } from "../../../shared/models/filters/filter.interface";
import { FilterEvent } from "../../../shared/models/filters/filter-event.interface";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";
import {
    THIRD_PARTY_GARAGE,
    NO_GARAGE,
    BLANK_GARAGE,
} from "src/app/planning/buses/const/const";

export class GarageLocationFilter implements IFilter {
    private event: FilterEvent;

    constructor(event?: FilterEvent) {
        this.event = event;
    }

    meets(
        planboardData: IPlanboardVehicleDataView[],
    ): IPlanboardVehicleDataView[] {
        if (!this.event) {
            return planboardData;
        }
        if (!this.event.criteria) {
            return planboardData;
        }
        if (!this.event.criteria.length) {
            return planboardData;
        }

        const matches = (garage) => (value: string) => {
            if (value === "other") {
                return [THIRD_PARTY_GARAGE, NO_GARAGE, BLANK_GARAGE].includes(
                    garage,
                );
            }
            return value === garage;
        };

        const byGarageLocation = (data: IPlanboardVehicleDataView) =>
            this.event.criteria
                .map((criteria) => criteria.value)
                .some(matches(data.garage));

        return planboardData.filter(byGarageLocation);
    }
}
