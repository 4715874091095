<div class="container" (clickOutside)="onClickedOutside($event)">
    <div class="container__inner">
        <div class="row-block underlined">
            <div class="row-block__data">
                <span
                    class="row-block__data-name row-block__data-name--unmarked"
                    >{{ "tripsOverview.bulkActions.totalTrips" | i18n }}</span
                >
                <span class="row-block__data-quantity">{{ totalTrips }}</span>
            </div>
        </div>
        <div class="row-block underlined">
            <div class="row-block__data">
                <span
                    class="row-block__data-name row-block__data-name--unmarked"
                    >{{ "tripsOverview.bulkActions.incomplete" | i18n }}</span
                >
                <span class="row-block__data-quantity">{{
                    availableStatuses["Incomplete"]
                }}</span>
            </div>
        </div>
        <div class="row-block row-block--hovered">
            <div class="row-block__data">
                <span class="indicator light-green"></span>
                <span class="row-block__data-name">{{
                    "tripsOverview.bulkActions.assigned" | i18n
                }}</span>
                <span class="row-block__data-quantity">{{
                    assignedQuantity
                }}</span>
                <span class="row-block__data-quantity--calculated" #assigned
                    >+{{ diff }}</span
                >
            </div>
            <div class="row-block__buttons">
                <button
                    class="row-block__button yellow"
                    (mouseenter)="showCalculatedData('Publish')"
                    (mouseleave)="hideCalculatedData()"
                    (click)="handleSwitchPublishClick()"
                    [disabled]="isPublishButtonDisabled"
                >
                    {{ "tripsOverview.bulkActions.publish" | i18n }}
                    <ge-svg
                        icon="arrow-in-circle"
                        width="16"
                        class="icon"
                    ></ge-svg>
                </button>
                <button
                    class="row-block__button purple"
                    (mouseenter)="showCalculatedData('Release')"
                    (mouseleave)="hideCalculatedData()"
                    (click)="
                        switchReleased(availableStatuses['Assigned'], true)
                    "
                    [disabled]="availableStatuses['Assigned'].length === 0"
                >
                    {{ "tripsOverview.bulkActions.release" | i18n }}
                    <ge-svg
                        icon="double-arrow-in-circle"
                        width="16"
                        class="icon"
                    ></ge-svg>
                </button>
            </div>
        </div>
        <div class="row-block row-block--hovered">
            <div class="row-block__data">
                <span class="indicator yellow"></span>
                <span class="row-block__data-name">{{
                    "tripsOverview.bulkActions.published" | i18n
                }}</span>
                <span class="row-block__data-quantity">{{
                    availableStatuses["Published"].length
                }}</span>
                <span class="row-block__data-quantity--calculated" #published
                    >+{{ diff }}</span
                >
            </div>
            <div class="row-block__buttons">
                <button
                    class="row-block__button light-green"
                    (mouseenter)="showCalculatedData('Unpublish')"
                    (mouseleave)="hideCalculatedData()"
                    (click)="
                        switchPublished(availableStatuses['Published'], false)
                    "
                    [disabled]="availableStatuses['Published'].length === 0"
                >
                    {{ "tripsOverview.bulkActions.unpublish" | i18n }}
                    <ge-svg
                        icon="arrow-in-circle"
                        width="16"
                        class="rotated icon"
                    ></ge-svg>
                </button>
                <button
                    class="row-block__button purple"
                    (mouseenter)="showCalculatedData('Release2')"
                    (mouseleave)="hideCalculatedData()"
                    (click)="
                        switchReleased(availableStatuses['Published'], true)
                    "
                    [disabled]="isReleaseButtonDisabled"
                >
                    {{ "tripsOverview.bulkActions.release" | i18n }}
                    <ge-svg
                        icon="arrow-in-circle"
                        width="16"
                        class="icon"
                    ></ge-svg>
                </button>
            </div>
        </div>
        <div class="underlined"></div>
        <div class="row-block row-block--hovered">
            <div class="row-block__data">
                <span class="indicator purple"></span>
                <span class="row-block__data-name">{{
                    "tripsOverview.bulkActions.released" | i18n
                }}</span>
                <span class="row-block__data-quantity">{{
                    availableStatuses["Released"].length +
                        availableStatuses["Partially Confirmed"].length
                }}</span>
                <span class="row-block__data-quantity--calculated" #released
                    >+{{ diff }}</span
                >
            </div>
            <div class="row-block__buttons">
                <button
                    *ngIf="tab === 'Drivers'"
                    class="row-block__button green"
                    (mouseenter)="showCalculatedData('Confirm')"
                    (mouseleave)="hideCalculatedData()"
                    (click)="
                        switchConfirm(
                            availableStatuses['Released'].concat(
                                availableStatuses['Partially Confirmed']
                            ),
                            true
                        )
                    "
                    [disabled]="
                        availableStatuses['Released'].concat(
                            availableStatuses['Partially Confirmed']
                        ).length === 0
                    "
                >
                    {{ "tripsOverview.bulkActions.confirm" | i18n }}
                    <ge-svg
                        icon="arrow-in-circle"
                        width="16"
                        class="icon"
                    ></ge-svg>
                </button>
            </div>
        </div>

        <div class="underlined"></div>
        <div class="row-block row-block--hovered">
            <div class="row-block__data">
                <span class="indicator green"></span>
                <span class="row-block__data-name">{{
                    "orders.details.tripStatuses.confirmed" | i18n
                }}</span>
                <span class="row-block__data-quantity">{{
                    availableStatuses["Released and Confirmed"].length
                }}</span>
                <span class="row-block__data-quantity--calculated" #confirmed
                    >+{{ diff }}</span
                >
            </div>
        </div>
    </div>
</div>

<ge-loader
    *ngIf="actionLoading"
    class="global-loader"
    [loading]="true"
></ge-loader>
