import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Renderer2,
    Output,
    EventEmitter,
} from "@angular/core";
import { PlanningDriversComponent } from "../drivers.component";
import { PlanningService } from "../../planning.service";
import { IPlanboardTrip } from "../../models/planboard-trip.model";
import { TripCardService } from "../../components/trip-card/trip-card.service";
import { ToasterNotificationService } from "src/app/shared/components/toaster-notification/toaster-notification.service";
import { I18nService } from "src/app/shared/i18n/i18n.service";
import { PlanningBusesComponent } from "../../buses/buses.component";
import { DriverCardService } from "../../components/driver-card/driver-card.service";
import { BusCardService } from "../../components/bus-card/bus-card.service";
import { ModalWindowService } from "../../../shared/components/modal-window/modal-window.service";
import { ConfirmModalComponent } from "../../../shared/components/confirm-modal/confirm-modal.component";
import { IModalOpenOptions } from "../../../shared/components/modal-window/modal-options.interface";
import { HandleNonblockingErrorService } from "../../common/services/handle-nonblocking-error.service";
import { GraphQLErrorResponse } from "../../common/const/const";

const buttonsConfiguration = [
    {
        name: "Publish",
        place: "published",
        editBlock: "assigned",
    },
    {
        name: "Definitive",
        place: "definitive",
        editBlock: "assigned",
    },
    {
        name: "Release2",
        place: "released",
        editBlock: "published",
    },
    {
        name: "Unpublish",
        place: "assigned",
        editBlock: "published",
    },
    {
        name: "Revert",
        place: "published",
        editBlock: "definitive",
    },
    {
        name: "Release",
        place: "released",
        editBlock: "assigned",
    },
    {
        name: "Revert2",
        place: "published",
        editBlock: "released",
    },
    {
        name: "Confirm",
        place: "confirmed",
        editBlock: "released",
    },
];

@Component({
    selector: "ge-drivers-popup",
    templateUrl: "./drivers-popup.component.html",
    styleUrl: "./drivers-popup.component.scss",
})
export class DriversPopupComponent implements OnInit {
    public availableStatuses = {
        Assigned: [],
        Published: [],
        Released: [],
        "Partially Confirmed": [],
        "Released and Confirmed": [],
        Driven: [],
        Incomplete: 0,
        "Driver Assigned": [],
    };
    public totalTrips = 0;
    public trips: IPlanboardTrip[] = [];
    public diff: number;
    public tab = "";
    public actionLoading = false;

    public parentRef: PlanningDriversComponent | PlanningBusesComponent;
    public currentButton: any;
    public savedBlockValue: string;
    public blockToChange: any;

    public isViewInitialized = false;
    @Output() dismiss = new EventEmitter<void>();

    @ViewChild("assigned") public assigned: ElementRef;
    @ViewChild("published") public published: ElementRef;
    @ViewChild("confirmed") public confirmed: ElementRef;
    @ViewChild("released") public released: ElementRef;

    constructor(
        private renderer2: Renderer2,
        private planningService: PlanningService,
        private el: ElementRef,
        private tripCardService: TripCardService,
        private toasterNotificationService: ToasterNotificationService,
        private i18nService: I18nService,
        private driverCardService: DriverCardService,
        private busCardService: BusCardService,
        private modalService: ModalWindowService,
        private handleNonblockingErrorService: HandleNonblockingErrorService,
    ) {}

    public ngOnInit(): void {
        this.initializeAvailableStatuses();
        this.getTripsIdsByStatuses();
    }

    get assignedQuantity(): number {
        return (
            this.availableStatuses["Assigned"].length +
            this.availableStatuses["Driver Assigned"].length
        );
    }

    get isPublishButtonDisabled(): boolean {
        return (
            this.availableStatuses["Assigned"].length === 0 &&
            this.trips.some(
                (trip) =>
                    trip.hasSecondDriver && trip.assignedDriversIds.length < 2,
            )
        );
    }

    get isReleaseButtonDisabled(): boolean {
        return (
            this.trips.some((trip) => !trip.busId) ||
            this.availableStatuses["Published"].length === 0
        );
    }

    private initializeAvailableStatuses(): void {
        Object.keys(this.availableStatuses).forEach((status) => {
            if (status !== "Incomplete") {
                this.availableStatuses[status] = new Set();
            }
        });
    }

    public getTripsIdsByStatuses(): void {
        this.trips.forEach((trip: IPlanboardTrip) => {
            if (trip.status in this.availableStatuses) {
                this.availableStatuses[trip.status].add(trip.tripId);
            } else {
                this.availableStatuses["Incomplete"]++;
            }
        });
        Object.keys(this.availableStatuses).forEach((status) => {
            if (status !== "Incomplete") {
                this.availableStatuses[status] = Array.from(
                    this.availableStatuses[status],
                );
            }
        });
    }

    public showCalculatedData(status: string): void {
        this.currentButton = buttonsConfiguration.find(
            (button) => button.name === status,
        );
        this.blockToChange =
            this[this.currentButton.editBlock].nativeElement.previousSibling;
        this.savedBlockValue = this.blockToChange.innerHTML;
        this.diff = this.blockToChange.innerHTML;
        this.blockToChange.innerHTML = 0;

        this.renderer2.setStyle(
            this[this.currentButton.place].nativeElement,
            "visibility",
            "visible",
        );
        this.renderer2.setStyle(this.blockToChange, "color", "#FF6D59");
    }

    public hideCalculatedData(): void {
        this.renderer2.removeStyle(
            this[this.currentButton.place].nativeElement,
            "visibility",
        );
        this.renderer2.removeStyle(this.blockToChange, "color");
        this.blockToChange.innerHTML = this.savedBlockValue;
    }

    public onClickedOutside(e: Event) {
        if (this.isViewInitialized) {
            if (!this.el.nativeElement.contains(e.target)) {
                this.dismiss.emit();
            }
        } else {
            this.isViewInitialized = true;
        }
    }

    public switchPublished(
        tripIds: number[],
        isPublished: boolean,
        skipNonBlockingValidation = false,
    ): void {
        this.actionLoading = true;
        this.planningService
            .setPublishedBulkAction(
                tripIds,
                isPublished,
                skipNonBlockingValidation,
            )
            .subscribe(
                (res: number[]) => this.afterBulkActionUpdated(res, tripIds),
                (error: GraphQLErrorResponse) => {
                    this.actionLoading = false;
                    this.handleNonBlockingError(error, tripIds, isPublished);
                },
            );
    }

    public switchReleased(tripIds: number[], isReleased: boolean): void {
        if (isReleased) {
            this.actionLoading = true;
        }
        this.planningService
            .setReleasedBulkAction(tripIds, isReleased)
            .subscribe(
                (res: number[]) => this.afterBulkActionUpdated(res, tripIds),
                (error: any) => this.bulkActionError(),
            );
    }

    public switchConfirm(tripIds: number[], isConfirmed: boolean): void {
        this.planningService
            .setConfirmBulkAction(tripIds, isConfirmed)
            .subscribe(
                (res: number[]) => this.afterBulkActionUpdated(res, tripIds),
                (error: any) => this.bulkActionError(),
            );
    }

    public afterBulkActionUpdated(response: number[], tripIds: number[]): void {
        this.actionLoading = false;
        this.driverCardService.activityStatusChanged$.next(true);
        this.busCardService.activityStatusChanged$.next(true);

        this.dismiss.emit();

        if (tripIds.length === response.length) {
            this.toasterNotificationService.successToaster(
                this.i18nService.getTranslatedWord("bulkActions.success"),
            );
        } else {
            this.toasterNotificationService.errorToaster(
                response.length +
                    this.i18nService.getTranslatedWord(
                        "bulkActions.partiallySucces",
                    ) +
                    "<br>" +
                    (tripIds.length - response.length) +
                    this.i18nService.getTranslatedWord(
                        "bulkActions.partiallyError",
                    ),
                this.i18nService.getTranslatedWord("errors.error"),
            );
        }
    }

    public bulkActionError(): void {
        this.actionLoading = false;
        this.toasterNotificationService.errorToaster(
            this.i18nService.getTranslatedWord("bulkActions.error"),
            this.i18nService.getTranslatedWord("errors.error"),
        );
    }

    public handleSwitchPublishClick(): void {
        if (
            !(
                this.availableStatuses["Assigned"].length ||
                this.availableStatuses["Driver Assigned"].length
            )
        ) {
            return;
        }
        this.switchPublished(
            [
                ...new Set([
                    ...this.availableStatuses["Assigned"],
                    ...this.availableStatuses["Driver Assigned"],
                ]),
            ],
            true,
        );
    }

    private handleNonBlockingError(
        error: GraphQLErrorResponse,
        tripIds: number[],
        isPublished: boolean,
    ): void {
        if (
            !this.handleNonblockingErrorService.isNonBlockingValidationError(
                error,
            )
        ) {
            this.bulkActionError();
        }
        const modalOptions: IModalOpenOptions = {
            data: {
                message: this.handleNonblockingErrorService.handleError(error),
            },
            ngbOptions: { centered: true },
        };
        this.modalService
            .open(ConfirmModalComponent, modalOptions)
            .subscribe((shouldContinue) => {
                if (shouldContinue) {
                    this.switchPublished(tripIds, isPublished, true);
                }
            });
    }
}
