<button
    class="legend-btn"
    (click)="toggle()"
    data-translate-me
    [translateMe]="'busAssignment.legend'"
>
    {{ "busAssignment.legend" | i18n }}
</button>

<div
    class="legend-wrapper"
    *ngIf="!isCollapsed"
    (clickOutside)="collapse($event)"
>
    <div *ngFor="let legend of legends">
        <div
            class="legend-square"
            [ngStyle]="{ background: legend.color }"
        ></div>
        <div class="legend-name">{{ legend.name }}</div>
    </div>
</div>
