/* src/app/planning/components/legend/legend.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.legend-square {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.legend-name {
  display: inline-block;
  top: -10px;
  position: relative;
  left: 10px;
}
.legend-btn {
  width: auto;
  height: 22px;
  color: #0b162d;
  margin-left: 10px;
  border-radius: 20px;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid rgba(219, 223, 232, 0.5);
}
.legend-wrapper {
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  width: 250px;
  z-index: 999;
  height: 350px;
  overflow-y: auto;
  right: 0;
  bottom: 25px;
  padding: 5px;
}
/*# sourceMappingURL=legend.component.css.map */
