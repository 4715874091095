import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GraphqlService } from "../../../services/graphql.service";
import { LEGEND_FRAGMENT } from "./legend.fragment";
import gql from "graphql-tag";

@Injectable({
    providedIn: "root",
})
export class LegendService {
    constructor(private graphService: GraphqlService) {}

    fetchClientTourOperatorData(): Observable<any> {
        const filter = { clientType: "TourOperatorClient" };
        const query = gql`
            query clients($filter: ClientFilter) {
                clients(filter: $filter) {
                    ...legendFragment
                }
            }
            ${LEGEND_FRAGMENT}
        `;

        const variables = {
            filter,
        };

        return this.graphService
            .query(query, variables)
            .pipe(map((res) => res.data));
    }
}
