import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";

import { LegendComponent } from "./legend.component";
import { LegendService } from "./legend.service";
import { NgClickOutsideDirective } from "ng-click-outside2";

@NgModule({
    declarations: [LegendComponent],
    imports: [CommonModule, NgClickOutsideDirective, SharedModule],
    providers: [LegendService],
    exports: [LegendComponent],
})
export class LegendModule {}
